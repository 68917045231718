import img1 from '../images/common/team_1.png';
import img2 from '../images/common/team_2.png';
import img3 from '../images/common/team_3.png';
import img4 from '../images/common/team_4.png';
 


const dataTeam = [
    {
        id: 1,
        img : img1,
        name: 'MR.DMYN',
        unit: 'Founder of Kiyobean Image'
    }, 
    {
        id: 2,
        img : img2,
        name: 'ROCKY',
        unit: 'Web3 Investment Expert'
    }, 
    {
        id: 3,
        img : img3,
        name: 'X先生',
        unit: 'Practitioners deeply involved in the trendy toy industry'
    }, 
    {
        id: 4,
        img : img4,
        name: 'hasky',
        unit: 'Web3 Industry Technology Development Team Consultant'
    } 
]

export default dataTeam;
import img1 from '../images/slider/Gif/gif/GM.gif'
import img2 from '../images/slider/Mojito_01.png'
import img3 from '../images/slider/icon_2.png'
import img4 from '../images/slider/icon_3.png'
import img6 from '../images/slider/6.png'

const dataBanner = [
  {
    id: 1,
    heading: 'KIYOBEAN NFT',
    sub_heading:
    "It's just a bean",
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
  },
  {
    id: 2,
    heading: 'KIYOBEAN AI',
    sub_heading: "Perhaps it's an AI bean",
    img1: img6,
    img2: img2,
    img3: img3,
    img4: img4,
  },
]

export default dataBanner

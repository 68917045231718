const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'NFT',
    links: '#',
    namesub: [
      /* {
        id: 1,
        sub: 'Mint NFT',
        links: '/project_v1',
      },
      {
        id: 2,
        sub: 'NFT Market',
        links: '/project_v2',
      },
      {
        id: 3,
        sub: 'My NFT',
        links: '/project_v3',
      }, */
      {
        id: 1,
        sub: 'Mint NFT',
        links: '/Coming',
      },
      {
        id: 2,
        sub: 'NFT Market',
        links: '/Coming1',
      },
      {
        id: 3,
        sub: 'My NFT',
        links: '/Coming2',
      },
      /* {
        id: 4,
        sub: 'Project 04',
        links: '/project_v4',
      },
      {
        id: 5,
        sub: 'Project 05',
        links: '/project_v5',
      },
      {
        id: 6,
        sub: 'Project List',
        links: '/project_list',
      },
      {
        id: 7,
        sub: 'Project Details',
        links: '/project_details',
      },
      {
        id: 8,
        sub: 'Submit Project',
        links: '/project_submit',
      }, */
    ],
  },
  /* {
    id: 3,
    name: 'Page',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Token',
        links: '/token',
      },
      {
        id: 2,
        sub: 'Connect Wallet',
        links: '/connect',
      },
      {
        id: 3,
        sub: 'Team Details',
        links: '/team_details',
      },
      {
        id: 4,
        sub: 'Submit IGO on chain',
        links: '/submit_igo',
      },
      {
        id: 5,
        sub: 'FAQs',
        links: '/faqs',
      },
      {
        id: 6,
        sub: 'Login',
        links: '/login',
      },
      {
        id: 7,
        sub: 'Register',
        links: '/register',
      },
      {
        id: 8,
        sub: 'Forget Password',
        links: '/forgetPass',
      },
    ],
  }, */
  {
    id: 4,
    name: 'White Paper',
    links: '/Roadmap',
  },
  /* {
    id: 5,
    name: 'Blog',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Blog Grid',
        links: '/blog_grid',
      },
      {
        id: 2,
        sub: 'Blog List',
        links: '/blog_list',
      },
      {
        id: 3,
        sub: 'Blog Detail',
        links: '/blog_details',
      },
    ],
  }, */
  /* {
    id: 6,
    name: 'Contact',
    links: 'contact',
  }, */
]

export default menus

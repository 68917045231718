import React, { useState, useEffect } from 'react'

import { Link, NavLink } from 'react-router-dom'
import menus from '../../pages/menu'
import Button from '../button/index'
import './styles.scss'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';


import logo from '../../assets/images/slider/Gif/gif/Rich.gif'
/* import Logo from './Logo.jsx' */

import { useTranslation } from '../../utils/contexts/Localization'

import LangSelector from "../../utils/LangSelector";
import {languageList} from "../../config/localization/languages";

const Header = () => {
  const [scroll, setScroll] = useState(false) 
 
  const { t } = useTranslation();

 
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 300)
    })
    return () => {
      setScroll({})
    }

    //地址处理
  }, [])

 

  const [menuActive, setMenuActive] = useState(null)

  const handleMenuActive = () => {
    setMenuActive(!menuActive)
  }

 
  const [activeIndex, setActiveIndex] = useState(null)
  const handleDropdown = (index) => {
    setActiveIndex(index)
  }

  return (
    <>  
    {/*  {wattleChoise&&<ConnectWallet close ={()=>{
          
     }}/>}  */}
    <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            
            <NavLink to="/">
              <img width={80} src={logo} alt="Risebot" />
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? 'active' : ''}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${
                    data.namesub ? 'menu-item-has-children' : ''
                  } ${activeIndex === idx ? 'active' : ''}`}
                >
                  <Link to={data.links}>{t(data.name)}</Link>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li key={submenu.id} className="menu-item">
                          <NavLink to={submenu.links} onClick={handleDropdown}>
                            {t(submenu.sub) }
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <LangSelector
              currentLang="zh"
              langs={languageList} 
              dropdownPosition="top-right"
            />
         {/*  <Button
            title={  'Connect'
            }
            path="/"
            addclass="style1"
        
 
          /> */}
        <div   className ="buttonconnect"><WalletMultiButton addclass="style1" />  </div>
         

       
          <div
            className={`mobile-button ${menuActive ? 'active' : ''}`}
            onClick={handleMenuActive}
          >
            <span></span>
         
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default Header

import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta';
import { Chart } from '../components/token/Chart';


function Token(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='Token' />}

            <section className="tf-section tf-token pt60">
                      <div className="container">
                    
                    <div className='coming'>
                       <h3>coming soon</h3>
                    </div>  </div>
                <div className="container">
                    
                   <div className='coming'>
                     <img  width={200} src={require("../assets/images/slider/Gif/gif/Sad.gif")}/></div>
                   </div>
            </section>

            {<CTA />}
            
        </div>
    );
}

export default Token;
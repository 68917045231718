import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';

import icon from '../assets/images/common/down.svg'




function Roadmap(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='RoadMap' />}

            <section className="tf-section roadmap">
        <div className="container">
             <img src={require ('../assets/images/papper/kiyobean_00.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_01.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_02.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_03.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_04.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_05.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_06.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_07.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_08.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_09.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_10.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_11.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_12.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_13.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_14.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_15.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_16.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_17.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_18.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_19.png')}/>
             <img src={require ('../assets/images/papper/kiyobean_20.png')}/> 
           
        </div>
    </section>
            {<CTA />}
            
        </div>
    );
}

export default Roadmap;
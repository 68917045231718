import { React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import routes from './pages';
import '../src/assets/fonts/font-awesome.css'
import Header from './components/header';
import Footer from './components/footer';
import { ThemeProvider } from 'styled-components'
import { dark } from '@pancakeswap/uikit'
import { ToastListener, ToastsProvider } from './utils/contexts/ToastsContext'
import { LanguageContext, LanguageProvider } from './utils/contexts/Localization/Provider'

import { WalletContextProvider } from './utils/contexts/WalletProvider';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import { parse } from 'querystringify'
import './i18n';
import '@solana/wallet-adapter-react-ui/styles.css';

const StyledThemeProvider = (props) => {
  return <ThemeProvider theme={dark} {...props} />
}


function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);



  
  const getPageQuery = () => parse(window.location.href.split('?')[1])

  const locationQuery = getPageQuery()
  if (locationQuery && locationQuery.invite) {
    // 读取base64字符串
    
    // 进行解码
    localStorage.setItem('invite_address', locationQuery.invite.toString())
  }

  return (
    <>


 

     <WalletContextProvider>
    <LanguageProvider>
        <ToastsProvider>
          <StyledThemeProvider>
            <Header />

            <Routes>

              {
                routes.map((data, idx) => (
                  <Route key={idx} path={data.path} element={data.component} exact />
                ))
              }

            </Routes>


            <Footer />
            <ToastListener />
            
          </StyledThemeProvider>
        </ToastsProvider>
      </LanguageProvider></WalletContextProvider>
    </>
  );
}

export default App;
import { ethers } from 'ethers';

// if (location.search && location.search.match(/enter-test-mode/)) localStorage.setItem('IsWolfTownTest', 'true');
// localStorage.setItem('IsWolfTownTest', 'true');
// const IsTest = localStorage.getItem('IsWolfTownTest') === 'true';

let DEBUG_ADDRESS = '';


const IsTest = true;

export const ImgUrl = "https://img.pinft.vip/img/";

const IsDevelopment = process.env.NODE_ENV === 'development';
export const Constants = {
  ArenaHistoryBlock: (24 * 60 * 60 * 30) / 3,
  DEBUG_ADDRESS,
  IsDevelopment,
  DEFAULT_SEED: '0x0000000000000000000000000000000000000000000000000000000000000000',
  /**
   * 每个邀请最多可持续的时间 (hours)
   */
  INVITATION_DURATION: 72,

  IsTest,
  BASE_URL: 'https://app.wolftown.world/animals',
  BASE_IMAGE_URL: 'https://app.wolftown.world/images/animals',
  API_SERVE: 'https://app.wolftown.world',
  OPENSEA_URL: 'https://opensea.io',
  Chain: {
    ID: '137',
    PRC: 'https://rpc.ankr.com/polygon/',
    Name: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    ScanView: 'https://polygonscan.com/',
    AddressView: 'https://polygonscan.com/address',
    TxView: 'https://polygonscan.com/tx',
  },

  emergencyWolfLeave: false,

  Building: {
    OwnershipTotal: 10000000,
  },
  Contract: {
    BankAddress: '0x1f0c693F5cD661126e43b25a96FA703a0C8F2543',
    WTWool: '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
    USDT: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    PiPandaMint: '0xe57F05aB9dc009c08bb082C0e24c420cbb22AC82',
    PiPandaMintUSDT: '0x9cbb01b1E9f894CF96C8997E1b04E61CC2e19664', 
    PiPandaNFT: '0x09aAF487b7B92Aef37BA458B47AEbDD1eAb91F86',
    BatchOpen:"0x0e7362026cB0C8E6A0F765d311b46d95aF3905c4",
    MarketPlace:"0xE464862E0b2Bf54B8f6194B7f6c9f0fCE59ed21d"
  },

  //batch admin 0xc501fe2954f6BAC66E74e93EA0C841F1b5958B93
};
export const AbiConfig: Record<keyof typeof Constants.Contract, any> = {
  BankAddress: require('src/utils/abi/wtWool'),
  WTWool: require('src/utils/abi/wtWool'),
  USDT: require('src/utils/abi/wtWool'), 
  PiPandaMint: require('src/utils/abi/PiPandaMint.json'),
  PiPandaMintUSDT: require('src/utils/abi/PiPandaMint.json'),
  PiPandaNFT: require('src/utils/abi/PiPandaNFT.json'), 
  BatchOpen: require('src/utils/abi/Batch.json'),
  MarketPlace: require('src/utils/abi/MarketPlace.json'),
};
if (!IsDevelopment) {
  console.log = () => null;
  console.error = () => null;
  console.info = () => null;
}
if (IsTest) {
  Object.assign(Constants, {
    BASE_URL: 'https://app.test.wolftown.world/animals',
    BASE_IMAGE_URL: 'https://app.test.wolftown.world/images/animals',
    API_SERVE: 'https://app.test.wolftown.world',
    Chain: {
      ID: '97',
      PRC: 'https://developer-access-mainnet.base.org',
      Name: 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETG',
        decimals: 18,
      },
      ScanView: 'https://testnet.bscscan.com',
      AddressView: 'https://testnet.bscscan.com/address',
      TxView: 'https://testnet.bscscan.com/tx',
    },
    Contract: {
      // api
      WoolfTownAPI: '0xbFE5a21f0c153144BE0c65BE961b01Bf52EE1B0f', 
      // contract
      WTWool: '0x808156c8510f3b07209762d1357da452Bc740067',
      USDT: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      PiPandaMint: '0x28E31353d87C994748c61B9E6692B4E7bFb92951',
      PiPandaNFT: '9Xow1T7b1Y2Yi73tMZooH6TCvYjWRvBvxY2KdoMNpump',   // ADMIN  0x557897D0B29b127AB0DcAdB888145A846446e358
      BatchOpen:"0x77395936b39263bc8540efc2f1a6d1822383ee42"   
    },
  });
}

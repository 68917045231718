import React, { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import {
  PhantomWalletAdapter,
  TokenPocketWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';

// 配置 Solana 网络，这里使用 devnet，可以根据需求修改
const network = 'devnet';
const endpoint = clusterApiUrl(network);

export const WalletContextProvider = ({ children }) => {
  // 创建钱包适配器
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new TokenPocketWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          {children}
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
